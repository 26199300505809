module.exports = {
    
    generateEventPageUrl: function(event, title){
        const eventDate = typeof event.start.getMonth == 'function' ? event.start :
            new Date(`2021-${event.start.month}-${event.start.day}T${event.start.hours}:${event.start.minutes}`);
        return title ? `${title.replace(/\s/g, '_')}-${event.formId}` :
            `${eventDate.toLocaleString('default', {month: 'long'})}_${eventDate.toLocaleString('default', {day: 'numeric'})}-${event.location.title.replace(/\s/g, '_')}-${event.formId}`
    },
    CarouselLD: function(itemList){
        JSONLD.call(this);
        this['@type'] = 'ItemList';
        this.itemListElement = itemList.map((item, index) => {
            return {
                "@type": "ListItem",
                "position": index,
                "item": {...item}
            }
        });
    },//TODO this function is weak, if src set strings ever change it could break a lot
    getSourceSet: function(srcSetString, prepend=''){
        console.log(prepend);
        return JSON.stringify(srcSetString.split(',\n').map((path) => {
            let newPath = path;
            if(prepend!=''){
                newPath=prepend+newPath;
            }    
            return newPath.split(' ')[0]
        }));
    },
    compileAddress: function(addressObj){
        console.log(addressObj);
        console.log(`${addressObj.street},${addressObj.locality} ${addressObj.region},${addressObj.postalCode}`);
        return `${addressObj.street}, ${addressObj.locality} ${addressObj.region}, ${addressObj.postalCode}`
    }
}

const JSONLD = function(){
    this['@context'] = 'https://schema.org'
}