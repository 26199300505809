import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { GatsbyImage } from "gatsby-plugin-image"
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import ShareIcon from '@material-ui/icons/Share';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AvatarMenu from './AvatarMenu';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { graphql } from "gatsby"
import clsx from  'clsx';
import { ListItem } from '@material-ui/core';
import { CoachDetails } from './CoachCard';
import CoachesData from '../content/coaches.json';
import ShareButton from './ShareButton';
import Backdrop from '@material-ui/core/Backdrop';


// import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
// import PlayArrowIcon from '@material-ui/icons/PlayArrow';
// import SkipNextIcon from '@material-ui/icons/SkipNext';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column',
      height: '90%',
      width: '95%',
      /*The below styles are reused on various card borders, move to theme*/
      border: '2px ridge #F78D1F',
      borderTop: 'none',
      borderLeft: 'none',
      // These are necessary to nest this way so that hovering over share button doesn't disable hover effect on parent
      '&:hover ': {
        '& .contentContainer': {
          transition: '.4s ease-in-out',
          transform: 'scale(1.2)',
          transitionDelay: '.1s'
        }
      }
    },
    expanded: {
      position: 'absolute'
    },
    flipPerspective: {
      perspective: '1000px',
      width: '35vw',
      minWidth: '260px',
      minHeight: '400px',
      height: '50vh',
    },
    landscapePerspective: {
      perspective: '1000px',
      width: '30vw',
      minWidth: '250px',
      minHeight: '330px',
      height: '85vh'
    },
    content: {
      flex: '1 0 auto',
      padding: '0px',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-evenly',
      overflowY: 'scroll',
      transition: '.4s ease-in-out',
      height: '100%'
      
    },
    header: {
      paddingTop: '0px',
      paddingBottom: '0px',
      color: 'white',
      textAlign: 'center',
      background : 'linear-gradient(20deg, #243E83, #45BFEE)'//TODO This is reused in coach card, move it to theme
    },
    cover: {
      flex: '1'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      justifyContent: 'space-between'
    },
    playIcon: {
      height: 38,
      width: 38,
    }
  }));

  export default function GalleryCard(props) {
    const {title, time, location, image, date, formId, ageGroups, variant, closePopover, details, dimensions, shareUrl} = props;
    const classes = useStyles();
    const theme = useTheme();
    const smallHeight = useMediaQuery('(max-height: 500px)')
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const subHeader = () => { return(smScreen ? <><Typography variant="caption"><i style={{textDecoration: 'underline', color: 'white', marginTop: '-5px'}}>{time.day}, {time.hours}</i></Typography>
    </>: `${time.day}, ${time.hours}`)}
  
    // const [context, setContext] = React.useState(false); we don't use tehse unless share button needs them
    const [expanded, setExpanded] = React.useState(props.expanded);

  //TODO make reusable function
    const shareEvent = function(){
      try{
        navigator.share({
          title: date ? date : document.title, 
          text: details, 
          url: shareUrl }).then(() => {
            // console.log("shared");
          }).catch((err) => {
            // console.log(err);
          });
      }catch(e){
        // console.log('no share support', e)
      }
    }
  
    return (
      <Card className={clsx(classes.root, expanded ? classes.expanded : smallHeight ? classes.landscapePerspective : classes.flipPerspective)} 
        style={{border: variant == 'popover' && 'none', ...props.styleOverride}}
        // onMouseOver={() => setContext(true)} onMouseLeave={() => setContext(false)} we don't use these unless the share buttonneeds them
        id={props.id}>
        <CardContent className={clsx(classes.content, !expanded && 'contentContainer')} style={{padding: '0px'}}>
            <GatsbyImage image={image} alt={details} style={{height: '100%'}}/>
            <Grid item xs={6} sm={3} md={3} lg={2} xl={2} style={{position: 'absolute', right: '0', position: 'absolute', height: '100%', width: '100%'}}>
              <Backdrop style={{position: 'absolute', zIndex: '2', color: '#fff', display: 'block', textAlign: 'center', paddingTop: '25px', marginLeft: '25px'}} open={expanded}>
                <Typography variant="h4" style={{marginBottom: '15px'}}>{date}</Typography>
                <Typography variant="body" paragraph="true" style={{paddingLeft: '1vw', paddingRight: '1vw'}}>
                  {details}
                </Typography>
              </Backdrop>
            </Grid>
        </CardContent>
        {/* <ShareButton clickHandler={shareEvent} contextual={true} currentContext={context}/> this used to only show the button when we hovered over image*/}
        {expanded && <ShareButton clickHandler={shareEvent} contextual stylesOverride={{zIndex: '2'}}/>}
      </Card>
    );
  }

export const cardImage = graphql`
  fragment cardImage on File {
    childImageSharp {
      gatsbyImageData(
        placeholder: TRACED_SVG
        formats: [AUTO, WEBP, AVIF]
      )
      resize {
        height
        width
        src
      }
    }
  }
`