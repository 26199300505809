import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { GatsbyImage } from "gatsby-plugin-image"
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import ShareIcon from '@material-ui/icons/Share';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AvatarMenu from './AvatarMenu';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { graphql } from "gatsby"
import clsx from  'clsx';
import { ListItem } from '@material-ui/core';
import { CoachDetails } from './CoachCard';
import CoachesData from '../content/coaches.json';
import ShareButton from './ShareButton';
import { compileAddress } from '../utils';

// import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
// import PlayArrowIcon from '@material-ui/icons/PlayArrow';
// import SkipNextIcon from '@material-ui/icons/SkipNext';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column',
      position: 'absolute',
      transition: '.5s',
      transform: 'rotateY(0deg)',
      backfaceVisibility: 'hidden',
      height: '100%',
      width: '100%',
      /*The below styles are reused on various card borders, move to theme*/
      border: '2px ridge #F78D1F',
      borderTop: 'none',
      borderLeft: 'none'
    },
    cardBack: {
      height: '100%', 
      width: '100%', 
      position: 'absolute',
      transition: '.5s',
      transform: 'rotateY(180deg)',
      backfaceVisibility: 'hidden'
    },
    content: {
      flex: '1 0 auto',
      padding: '14px',
      paddingTop: '0px',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-evenly',
      overflowY: 'scroll'
    },
    header: {
      paddingTop: '0px',
      paddingBottom: '0px',
      color: 'white',
      textAlign: 'center',
      background : 'linear-gradient(20deg, #243E83, #45BFEE)'//TODO This is reused in coach card, move it to theme
    },
    cover: {
      flex: '1'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      justifyContent: 'space-between'
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    flipPerspective: {
      perspective: '1000px',
      width: '35vw',
      minWidth: '260px',
      minHeight: '400px',
      height: '50vh',
    },
    landscapePerspective: {
      perspective: '1000px',
      width: '30vw',
      minWidth: '250px',
      minHeight: '330px',
      height: '85vh'
    },
    flipContainer: {
      transition: '.5s',
      position: 'relative',
      display: 'flex',
      flexFlow: 'column',
      transformStyle: 'preserve-3d',
      height: '100%'
    },
    front: {
      transform: 'rotateY(0deg)'
    },
    backLeft: {
      transform: 'rotateY(-180deg)'
    },
    backRight: {
      transform: 'rotateY(180deg)'
    },
    frontFace: {
      zIndex: 1,
      
    },
    backFace: {
      zIndex: -1   
    }
  }));

  //TODO this will eventually come from our headless cms config
  const ages = [10, 11, 12, 13, 14, 15, 16, 17, 18]

  //TODO minutes have to be single digit in JSON aka no leading 0s for this to work seamlessly.
  export const formatEventHours = (start, end) => {
    const startInt = parseInt(start.hours);
    const endInt = parseInt(end.hours);
    const startStr = startInt < 12 ? `${startInt}:${start.minutes < 10 ? start.minutes: start.minutes}am` : `${startInt-12 == 0 ? 12 : startInt-12}:${start.minutes < 10 ? start.minutes: start.minutes}pm`
    const endStr = endInt < 12 ? `${endInt}:${end.minutes < 10 ? end.minutes: end.minutes}am` : `${endInt-12 == 0 ? 12 : endInt-12}:${end.minutes < 10 ? end.minutes: end.minutes}pm`
    return  `${startStr} - ${endStr}`
  }


  export default function EventCard(props) {
    const {title, time, location, image, coaches, formId, ageGroups, variant, closePopover, cta="RSVP", dimensions, shareUrl} = props;
    const classes = useStyles();
    const theme = useTheme();
    const smallHeight = useMediaQuery('(max-height: 500px)')
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const [flipped, setFlipped] = React.useState(false)
    const [coach, selectCoach] = React.useState(false)
    const subHeader = () => { return(smScreen ? <><Typography variant="caption"><i style={{textDecoration: 'underline', color: 'white', marginTop: '-5px'}}>{time.day}, {time.hours}</i></Typography>
    </>: `${time.day}, ${time.hours}`)}
  
    const shareEvent = function(){
      try{
        navigator.share({
          title: document.title, 
          text: `${title}\n${time.day} @ ${location.title}`, 
          url: shareUrl }).then(() => {
            // console.log("shared");
          }).catch((err) => {
            // console.log(err);
          });
      }catch(e){
        // console.log('no share support', e)
      }
    }
  
    return (
      <div className={smallHeight ? classes.landscapePerspective : classes.flipPerspective} style={{...dimensions}}>
      <div className={clsx(classes.flipContainer, flipped ? classes.backLeft : (smScreen && coach ? classes.backRight: classes.front))} >
      <Card className={clsx(classes.root, flipped ? classes.backFace : (smScreen && coach ? classes.backFace : classes.frontFace))} style={{border: variant == 'popover' && 'none'}}>
        <CardHeader
            title={title}
            subheader={subHeader()}
            className={classes.header}
            subheaderTypographyProps={{color: 'white'}}
            style={{textAlign: variant == 'popover' && 'left', flex: '0 0 15%', whiteSpace: 'nowrap'}}
            action={variant == 'popover' && <IconButton onClick={closePopover}><CloseIcon style={{color: 'white', marginTop: '5px', marginRight: '-10px'}}/></IconButton>}
          />
        <Grid container direction='row' wrap="nowrap" item style={{flex: '1', overflow: 'hidden'}}>
        <Grid item xs={8} sm={4} style={{minWidth: smScreen ? '175px' : '195px', display: 'flex', flexFlow: 'column nowrap'}}>
          
          <CardContent className={classes.content}>
            <Grid item>
              <Typography variant="subtitle1" style={{borderBottom: '1px solid #F78D1F', marginBottom: '5px', color: '#243E83'}}>Location</Typography>
              <Typography variant="caption">
                <address style={{display: smallHeight && 'flex'}}><b>{location.title}</b><br></br>
                  {!smallHeight && compileAddress(location.address)} 
                  <a href={`https://maps.google.com/?q=${location.geo}`} target="_blank"><LocationOnIcon color="primary" style={{verticalAlign: 'middle', paddingLeft: '2px'}}/></a>
                </address>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" style={{borderBottom: '1px solid #F78D1F', marginBottom: '5px', color: '#243E83'}}>Age Groups</Typography>
              <Typography variant="caption">{ageGroups}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" style={{borderBottom: '1px solid #F78D1F', marginBottom: '10px', color: '#243E83'}}>Coaches</Typography>
              <AvatarMenu variant="stacked" labelVariant="tooltip">
                {coaches.map((coachData) => {
                  return <GatsbyImage image={coachData.image} alt={coachData.name} selected={coach === coachData.id} label={coachData.label} dispatch={() => {selectCoach(coachData.id)}}/>
                })}
              </AvatarMenu>
            </Grid>
          </CardContent>
          <div className={classes.controls}>
            <Button size="small" color="primary" variant="contained" onClick={() => setFlipped(true)}>{cta}</Button>
            <ButtonGroup color="primary" aria-label="outlined primary button group">
              <ShareButton clickHandler={shareEvent}/>
            </ButtonGroup>
          </div>
        </Grid>
        <Grid item xs={4} sm={8} style={{height: '100%', width: '100%'}} container>
          <div style={{width: '100%', perspective: '1000px'}}>
          <div className={clsx(classes.flipContainer, coach ? classes.backLeft : classes.front)} >
            <GatsbyImage image={image} alt={"Beach Volleyball Player"} style={{height: '100%', position: 'absolute', border: 'none'}} className={clsx(classes.root, coach ? classes.backFace : classes.frontFace)}/>
            <Card className={clsx(classes.cardBack, coach ? classes.frontFace : classes.backFace)} style={{display: 'flex', flexFlow: 'column'}}>
              <CardHeader className={classes.header} style={{textAlign: 'left', background: '#45BFEE', borderBottom: '2px solid #F99E43', borderBottomLeftRadius: '35px'}} 
                title={CoachesData[coach] && CoachesData[coach].name} titleTypographyProps={{variant: 'h6'}}
                action={<IconButton onClick={() => selectCoach(false)}><CloseIcon style={{color: 'white', marginTop: '5px', marginRight: '-10px'}}/></IconButton>}/>
              <CardContent className={classes.content} style={{flex: '1'}}>
                <CoachDetails className={clsx(classes.cardBack, coach ? classes.frontFace : classes.backFace)} coachData={CoachesData[coach]}/>
              </CardContent>
            </Card>
          </div>
          </div>
        </Grid>
        </Grid>
      </Card>
      <Card className={clsx(classes.cardBack, flipped ? classes.frontFace : classes.backFace)} style={{display: 'flex', flexFlow: 'column'}}>
        <CardHeader className={classes.header} style={{textAlign: 'left'}} subheaderTypographyProps={{color: 'white'}} title={`${title}`} subheader={subHeader()}
          action={<IconButton onClick={() => setFlipped(false)}><CloseIcon style={{color: 'white', marginTop: '5px', marginRight: '-10px'}}/></IconButton>}/>
        <CardContent className={classes.content}>
          <iframe src={`https://formcrafts.com/a/${formId}?iframe=true&resizer=false&innerHeight=625`} height='100%' width='100%'
          style={{maxWidth: '100%', border: '0', boxShadow: '0 0 0 .5px rgba(30, 30, 30, .1), 0px 1px 2px rgb(30, 30, 30, .08)', 
          borderRadius: '3px', height: '100%', flex: '1', marginBottom: '0px'}}>Loading</iframe>
        </CardContent>
      </Card>
      <Card className={clsx(classes.cardBack, smScreen && coach ? classes.frontFace : classes.backFace)} style={{display: 'flex', flexFlow: 'column'}}>
      <CardHeader className={classes.header} style={{textAlign: 'left'}} 
        title={CoachesData[coach] && CoachesData[coach].name} titleTypographyProps={{variant: 'h6'}}
        action={<IconButton onClick={() => selectCoach(false)}><CloseIcon style={{color: 'white', marginTop: '5px', marginRight: '-10px'}}/></IconButton>}/>
        <CardContent className={classes.content} style={{flex: '1'}}>
          <CoachDetails className={clsx(classes.cardBack, coach ? classes.frontFace : classes.backFace)} coachData={CoachesData[coach]}/>
        </CardContent>
      </Card>
      </div>
      </div>
    );
  }

export const cardImage = graphql`
  fragment cardImage on File {
    childImageSharp {
      gatsbyImageData(
        placeholder: TRACED_SVG
        formats: [AUTO, WEBP, AVIF]
      )
      resize {
        height
        width
        src
      }
    }
  }
`