import React, { useRef, useEffect } from 'react';
import IconButton from "@material-ui/core/IconButton";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EventIcon from '@material-ui/icons/Event';
import Button from '@material-ui/core/Button';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { Link } from "gatsby"

const useStyles = makeStyles((theme) => ({
    indicator: {
        transition: "200ms",
        padding: 0,
        '&.target': {
            color: '#F99E43'//TOOD use theme
        }
    },
    carouselContainer: {
        
        overflowX: 'scroll',
        scrollbarWidth: 'none', /* Firefox */
        msOverflowStyle: 'none',  /* Internet Explorer 10+ */
        '&::-webkit-scrollbar': { /* WebKit */
            width: '0',
            height: '0'
        },
        // '&::-webkit-scrollbar-thumb': {
        //     background: '#FF0000'
        // }//this is intersting because it overrides scrollbar styles,
        // paddingTop: '15px',
        // scrollBehavior: 'smooth'
    },

    scene: {
        position: 'relative',
        perspective: '1000px',
        perspectiveOrigin: 'top'
    },
    //TODO rename to 3d carousel
    carousel: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        transformStyle: 'preserve-3d',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    carousel_cell: {
        position: 'absolute',
    }
}));

const offset = 0;//This value is equal to the padding-right for the li items TODO fix this

export default function Carousel(props){

    /*
        Since we know the elements and the clientWidth and the clientScrollWidth we can dtermine the size of each
        element and hence how many will fit per clientWidth. From there we can figure out appropriate scroll
    */
   
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [activeStep, setActiveStep] = React.useState(0);
    const scrollContainer = useRef(null);
    const indicators = Math.round(smallScreen ? props.items : props.items/2);//TODO items prop should not really be needed, just derive from children.length
    const handleNext = () => {
        //Keynote setting scroll behaviour here manually because when we use # urls to focus element it scrolls smoothly to it which we don't want
        scrollContainer.current.style.scrollBehavior = 'smooth';
        setActiveStep(activeStep === indicators-1 ? activeStep : activeStep+1);
        scrollContainer.current.scrollLeft+=(scrollContainer.current.scrollWidth - scrollContainer.current.clientWidth+offset)/(indicators-1);
    };

    const handleBack = () => {
        
        //Keynote setting scroll behaviour here manually because when we use # urls to focus element it scrolls smoothly to it which we don't want
        scrollContainer.current.style.scrollBehavior = 'smooth';
        setActiveStep(activeStep === 0 ? 0 : activeStep - 1);
        scrollContainer.current.scrollLeft-=(scrollContainer.current.scrollWidth - scrollContainer.current.clientWidth+offset)/(indicators-1);
    };

    const handleScroll = (event) => {
        const increment = (event.target.scrollWidth-event.target.clientWidth)/indicators;
        // console.log(increment);
        // console.log(activeStep)
        // console.log(event.target.scrollLeft);
        // console.log(Math.floor(event.target.scrollLeft/increment));
        if(activeStep<Math.floor(event.target.scrollLeft/increment) && activeStep!==indicators-1){
            setActiveStep(activeStep+1)
        }
        else if(activeStep>Math.floor(event.target.scrollLeft/increment)){
            setActiveStep(activeStep-1)
        }
    }

    useEffect(() =>{
        scrollContainer.current.addEventListener('scroll', handleScroll);
        return () => { scrollContainer.current && scrollContainer.current.removeEventListener('scroll', handleScroll)}
    });
    return (
        <Container id={props.id} style={{alignSelf: 'center', display: 'flex', flexFlow: 'column', paddingTop: '24px'}} maxWidth={'xl'}>
            <Container style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between'}} maxWidth={'xl'}>
                <Typography variant="h3" style={{marginTop: '5px', color: "#243E83"}}>{props.title}</Typography>
    {smallScreen ? <Link to={props.variant == 'gallery' ? "/gallery/" : "/clinics/"}><IconButton color="primary">{props.variant == 'gallery' ? <PhotoLibraryIcon/> : <EventIcon/>}</IconButton></Link> :
                    <Button variant="contained" size="small" color="primary" style={{alignSelf: 'flex-end'}} 
                        endIcon={props.variant == 'gallery' ? <PhotoLibraryIcon/> : <EventIcon/>}>
                        <Link to={props.variant == 'gallery' ? "/gallery/" : "/clinics/"} style={{color: 'white'}}>
                            {props.variant == 'gallery' ? "More Photos" : "Full Schedule"}</Link>
                    </Button>
                }
            </Container>
            <Container style={{display: 'flex', flexFlow: 'row', alignItems: 'center', padding: '0'}} maxWidth={'xl'}>
                <IconButton onClick={handleBack}><NavigateBeforeIcon/></IconButton>
                <Box ref={scrollContainer} className={classes.carouselContainer}>
                    {props.children}
                </Box>
                <IconButton onClick={handleNext}><NavigateNextIcon/></IconButton>
            </Container>
            <Indicators indicators={indicators} activeIndex={activeStep}/>
        </Container>
       
    )
}

const Indicators = ({indicators, activeIndex}) => {

    const classes = useStyles();
    return (
        <Container style={{display: 'flex', flexFlow: 'row', justifyContent: 'center', padding: '0px'}}>
            {[...Array(indicators).keys()].map(
                (el, indx) => <FiberManualRecordIcon color="primary" className={`${classes.indicator} ${indx == activeIndex && 'target'}`}/>)}
        </Container>
    )
}

export const ThreeDCarousel = (props) => {
    
    const classes = useStyles();
    const theme = useTheme();

    return (
    <div className={classes.scene} style={{...props.style}}>
    <div className={classes.carousel}>
        {props.children.map((child, index) =>{
            return <div className={classes.carousel_cell} 
                        style={{ transform: `rotateY(${index*(360/props.children.length)}deg) translateZ(388px)`}}>
                    {child}</div>;
        })}
    </div>
    </div>
    )

}